@use "sass:math";
@import 'links';

$container-width: 100%;
$container-height: 100vh;
$inner-width: $container-width * 0.75;
$title-spacing: 25px;


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

@keyframes slide-up {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(150vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes squish {
  from {
    transform: scaleY(100%);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#profile-photo {
  border-radius: 50%;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.4);
  align-self: center;
  animation: slide-left 0.5s ease-in-out 1.2s 1 forwards;
  transform: translateX(150vw);

  @media screen and (min-width: 501px) {
    min-height: 400px;
    min-width: 400px;
  }
  @media screen and (max-width: 500px) {
    min-height: 150px;
    min-width: 150px;
  }
}


.container {
  position: relative;
  display: block;
  width: $container-width;
  height: $container-height;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  mix-blend-mode: normal;
  overflow: hidden;
}

.full-height {
  position: absolute;
  width: 100%;
  background: #FCFF00;
  display: block;
  height: $container-height;
  animation: squish 0.8s forwards;
}


.color-block {
  width: $container-width;
  height: math.div($container-height, 2);
  display: flex;
  justify-content: center;
}

/* Bottom color */
.bottom-color {
  background: #D515AF;
}

/* Top color */
.top-color {
  background: #00fff7;
}

/* Name Title */
.name-title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;

  h1 {
    align-items: center;
    display: flex;
    font-size: 48px;

    @media screen and (max-width: 631px) {
      font-size: 36px;
    }

    @media screen and (max-width: 488px) {
      font-size: 24px;
    }
  }
}


$block-width: 70%;
$half-block: math.div($block-width, 2);
$height: 100px;

.center-block {
  position: absolute;
  display: flex;
  height: $height;
  transform: translateY(100vh);
  width: $block-width;
  left: calc(50% - #{$half-block});
  top: calc(50% - 50px);
  background: #000;
  animation: slide-up 0.5s ease-in-out 0.8s 1 forwards;

  @media screen and (max-width: 828px) {
    $height: 75px;
  }

  @media screen and (max-width: 457px) {
    $height: 50px;
    width: 90%;
    left: 5%;
  }
}